import domReady from '@roots/sage/client/dom-ready';
import axios from 'axios';

function mobileMainMenu() {
  function toggleMainMenu() {

    let toggleSiteHeader = document.querySelector('.site-header');
    toggleSiteHeader.classList.toggle('mobile-menu--active');
    let body = document.querySelector('body');
    body.classList.toggle('mobile-menu-is-active');
    // trigger menu animation
    triggerMenuAnimation();
  }
  let toggleButton = document.querySelector('[data-toggle-main-menu-button]');
  toggleButton.addEventListener('click', toggleMainMenu);

  // open the sign-up button in a new tab
  let signUpNavButton = document.querySelector('.nav--2 .nav li a');
  signUpNavButton.setAttribute('target', '_blank');
}

function triggerMenuAnimation() {
  function initIntIcon(i) {
    if (!i.classList.contains('js-nc-int-icon-loaded')) {
      i.classList.add('js-nc-int-icon-loaded');
    };
    i.classList.toggle('nc-int-icon-state-b');
  }
  for (var intIcons = document.getElementsByClassName('js-nc-int-icon'), i = 0; intIcons.length > i; i++) {
    initIntIcon(intIcons[i]);
  }
}

function getEventCategories(event) {
  // every event shall only have one category (city) assigned
  // this is just for edge cases where there are multiple categories assigned
  let categories = event && event['_embedded'] && event['_embedded']['wp:term'] ? event['_embedded']['wp:term'][0] : undefined;
  if (categories == undefined || categories.length == 0) {
    return '';
  }

  let eventCityNames = categories.map((category) => {
    return category['name']
  });

  return eventCityNames.join(', ');
}

function populatePersonContainer(wrapper, data) {
  // Styling in event-list.css

  data.map((person) => {
    let singleContainer = document.createElement('div');
    singleContainer.setAttribute('class', 'person-list-single-container');

    try {
      // container for content
      let contentContainer = document.createElement('div');
      contentContainer.classList.add('person-list-content-container');

      if (person && person['_embedded'] && person['_embedded']['wp:featuredmedia']) {
        const image = document.createElement('img');
        image.classList.add('attachment-medium', 'size-medium', 'wp-post-image');
        image.alt = '';
        const imageSrc = person['_embedded']['wp:featuredmedia'][0]['media_details']['sizes']['medium']?.source_url || person['_embedded']['wp:featuredmedia'][0]['media_details']['sizes']['full']['source_url'];
        image.src = imageSrc;
        contentContainer.appendChild(image);
      } else {
        const imagePlaceholder = document.createElement('div');
        imagePlaceholder.classList.add('image-placeholder');
        contentContainer.appendChild(imagePlaceholder);
      }

      const infoContainer = document.createElement('div');
      infoContainer.classList.add('person-list-single-info-container');

      const heading = document.createElement('h2');
      heading.classList.add('person-list-single-heading');
      heading.textContent = person.title.rendered;
      infoContainer.appendChild(heading);


      // add info container to main container
      contentContainer.appendChild(infoContainer);
      singleContainer.appendChild(contentContainer);

      wrapper.querySelector('.post-list-container').appendChild(singleContainer);
    } catch (error) {
      console.log(error);
    }

  });
}

// Function to parse the event date string and return a formatted date string with Sweden's time zone and to be comparable with the currentDate value
function parseEventDate(dateString) {
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZone: 'Europe/Stockholm', // Time zone for Sweden
  };
  const date = new Date(dateString);
  return date.toLocaleString('en-US', options);
}

/**
 *
 * @param {*} container the HTMLElement in which the events is being rendered
 * @param {*} data an array of events
 * @returns
 */
function renderSingleEvent(container, data) {
  // Styling in event-list.css
  const currentDate = new Date().setHours(0, 0, 0, 0);

  return data.map((event) => {
    let singleContainer = document.createElement('div');
    singleContainer.setAttribute('class', 'event-list-single-container');

    // link
    let permalinkElement = document.createElement('a');
    permalinkElement.setAttribute('class', 'animated-hover-item');
    permalinkElement.setAttribute('href', event.link);

    try {
      // container for content
      let contentContainer = document.createElement('div');
      contentContainer.classList.add('event-list-content-container');

      if (event && event['_embedded'] && event['_embedded']['wp:featuredmedia']) {
        const image = document.createElement('img');

        // getting the eventDate in a format that is comparable to currentDate
        const eventDate = new Date(parseEventDate(event.acf.event_date));

        image.classList.add('attachment-medium', 'size-medium', 'wp-post-image', (eventDate <= currentDate) ? 'passed-event-img' : 'future-event');
        image.alt = event['_embedded']['wp:featuredmedia'][0]['alt_text'] || '';
        const imageSrc = event['_embedded']['wp:featuredmedia'][0]['media_details']['sizes']['medium']?.source_url || event['_embedded']['wp:featuredmedia'][0]['media_details']['sizes']['full']['source_url'];
        image.src = imageSrc;
        contentContainer.appendChild(image);
      } else {
        const imagePlaceholder = document.createElement('div');
        imagePlaceholder.classList.add('image-placeholder');
        contentContainer.appendChild(imagePlaceholder);
      }

      const infoContainer = document.createElement('div');
      infoContainer.classList.add('event-list-single-info-container');

      const heading = document.createElement('h2');
      heading.classList.add('event-list-single-heading');
      const headingSpan = document.createElement('span');
      headingSpan.classList.add('animated-underline');
      headingSpan.textContent = String(event.title.rendered).replace('&#8211;', '-').replace('&amp;', '&');
      heading.appendChild(headingSpan);
      infoContainer.appendChild(heading);

      // event city
      let eventCity = event.acf.event_city ? event.acf.event_city : getEventCategories(event);

      let eventDate = document.createElement('p');
      eventDate.setAttribute('class', 'event-list-single-date');
      let eventDateSpan = document.createElement('span');
      let eventDateContent = event.acf.event_date ? event.acf.event_date.replace(',', '') : 'Datum kommer';
      let eventCityContent = eventCity ? eventCity : 'Plats kommer';
      eventDateSpan.textContent = `${eventDateContent} / ${eventCityContent}`;
      eventDate.appendChild(eventDateSpan);
      infoContainer.appendChild(eventDate);

      // add info container to main container
      contentContainer.appendChild(infoContainer);
      permalinkElement.appendChild(contentContainer);
      singleContainer.appendChild(permalinkElement);

      container.appendChild(singleContainer);

    } catch (error) {
      console.log(error);
    }

  })
}

function populateEventContainer(wrapper, data) {
  const postListContainer = wrapper.querySelector('.post-list-container')

  if (!data.length) {
    const placeholderText = wrapper.dataset.placeholderValue;

    return wrapper.innerHTML += `<p data-no-event-placeholder class="no-events-placeholder mt-14 mb-14 md:mt-20 lg:mt-24">${placeholderText}</p>`;
  }

  renderSingleEvent(postListContainer, data)

}

function getSearchParam(value) {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(value);
}

function setSearchParam(key, value) {
  const url = new URL(window.location.href);
  url.searchParams.set(key, value);
  history.pushState({}, '', url);
}

function emptyAndRefetch() {
  // Empty the wrapper by removing all child nodes
  const wrapper = document.querySelector('[data-posts-wrapper][data-post-type="event"]');
  const postListContainer = wrapper.querySelector('.post-list-container');
  const loadMoreButton = wrapper.querySelector('[data-load-more-button]')

  while (postListContainer.firstChild) {
    postListContainer.firstChild.remove();
  }

  if (loadMoreButton) {
    loadMoreButton.remove()
  }

  const noEventsPlaceholder = wrapper.querySelectorAll('[data-no-event-placeholder]')
  if (noEventsPlaceholder) {
    noEventsPlaceholder.forEach(element => {
      element.remove()
    })
  }



  //repopulate events wrapper
  loadPosts(wrapper, 1)
}

function eventFiltering() {
  const categoryButtonsWrapper = document.querySelector('[data-post-filters]');
  let currentCity;

  /**
   * Handle category choice
   */
  if (categoryButtonsWrapper) {
    categoryButtonsWrapper.addEventListener('click', (event) => {
      const isButton = event.target.nodeName === 'BUTTON';
      if (!isButton) {
        return;
      }
      const allPickButtons = categoryButtonsWrapper.querySelectorAll('[data-category-picker]')
      currentCity = event.target.dataset.categoryValue;
      // console.log(event.target)

      setSearchParam('kategori', currentCity)

      //remove active class from all other buttons
      if (allPickButtons) {
        // console.log(allPickButtons)
        allPickButtons.forEach(button => {
          // console.log(button)
          button.classList.remove('is-current-filter')
        })
      }
      //set active class to the clicked button
      event.target.setAttribute('class', 'is-current-filter')

      emptyAndRefetch();

    });

  }

  /**
   * Handle past/future event filter choice
   */
  const selectElement = document.querySelector('[data-event-filter-select]');

  if (selectElement) {
    // set select initially to the filter value from the slug (if any)
    const filterParamValue = getSearchParam('filter');
    if (filterParamValue) {
      // Check if the value matches any option
      const option = selectElement.querySelector(`option[value="${filterParamValue}"]`);
      option.selected = true;
    }

    selectElement.addEventListener('change', function () {
      const filterValue = selectElement.value;

      setSearchParam('filter', filterValue)

      emptyAndRefetch();

    });
  }

}

function loadPosts(wrapper, currentPage) {
  const {
    postType,
    postsPerPage,
    initialCategorySlug,
    embeds,
    productTypes,
    excludePosts,
    loadMoreButtonText,
  } = wrapper.dataset;

  const currentCategory = getSearchParam('kategori')

  if (!currentPage) {
    currentPage = 1;
  }

  let restUrl = `/wp-json/wp/v2/${postType}?_embed=${embeds}&per_page=${postsPerPage}&order=${getSearchParam('filter') === 'past' ? 'desc' : 'asc'}${postType === 'event' ? `&show=${getSearchParam('filter') || 'future'}` : '&orderby=title'}&acf_format=standard&page=${currentPage}`;

  if (currentCategory !== 'alla') {
    if (currentCategory) {
      restUrl += `&${postType}_category=${currentCategory}`

    } else if (initialCategorySlug) {
      restUrl += `&${postType}_category=${initialCategorySlug}`;

    }
  }

  if (excludePosts) {
    restUrl += `&exclude=${excludePosts}`;
  }

  if (productTypes) {
    restUrl += `&product-type=${productTypes}`;
  }

  axios.get(restUrl)
    .then(function (response) {
      if (postType === 'event') {
        populateEventContainer(wrapper, response.data);

      } else if (postType === 'person') {
        populatePersonContainer(wrapper, response.data);
      }

      const loadMoreButton = document.querySelector('[data-load-more-button]');
      //remove the old button (if any) in order to create a new
      if (loadMoreButton) {
        loadMoreButton.remove()
      }

      // Are there more posts to show?
      if (loadMoreButtonText && Number(response['headers']['x-wp-totalpages']) > currentPage) {
        // Add load more button
        let button = document.createElement("button");
        button.textContent = loadMoreButtonText;
        button.setAttribute('class', 'button small');
        button.setAttribute('data-load-more-button', '');
        button.addEventListener('click', function () {
          if (Number(response['headers']['x-wp-totalpages']) <= currentPage ?? response.length > postsPerPage) {
            return button.remove();
          }
          currentPage++;
          loadPosts(wrapper, currentPage);
        });
        if (button) {
          wrapper.appendChild(button);
        }
      }
    })
    .catch(function (error) {
      console.log('error', error);
    });
}

function loadPostsInit() {
  let postWrappers = document.querySelectorAll('[data-posts-wrapper]');

  postWrappers.forEach((wrapper) => {
    const postContainer = document.createElement("div");
    postContainer.setAttribute('class', 'post-list-container');
    wrapper.appendChild(postContainer);
    loadPosts(wrapper);
  })
}

function addAttributes() {
  const footerSignUp = document.querySelector('footer div.site-footer-container .menu-header-2-container a')
  footerSignUp.setAttribute('target', '_blank');
}

/**
 * Application entrypoint
 */
domReady(async () => {
  mobileMainMenu();
  loadPostsInit();
  eventFiltering();
  addAttributes()
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);
